
import {Component, Vue, Watch} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import BorcluForm from "@/components/forms/BorcluForm.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import AlacakList from "@/components/lists/AlacakList.vue";
import TakipAlacakliPicker from "@/components/pickers/TakipAlacakliPicker.vue";
import DokumanList from "@/components/lists/DokumanList.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import {StatusCodeHelper} from "@/helpers/StatusCodeHelper";
import {ProgramPaths} from "@/enum/ProgramPaths";

@Component({
  components: {DokumanList, TakipAlacakliPicker, AlacakList, FormWrapper, BorcluForm, FormDialog, ProgramPaths}
})
export default class TopluIslerView extends Vue {

  bitis: any;
  baslangic: any;
  takip: TakipEntity | null = null;
  araliktakiTakipler = [];
  parser: any;
  xmlDoc: any = [];
  xml = "";
  stepler = [];
  secimler: boolean = false;
  indirmeyeDevamEt: boolean = false;

  xmlObject = [{
    kart_no: "",
    messages: "",
    status: false,
    color: ""
  }];

  headers = [
    {text: "Kart No", value: "kart_no",align:'start', class: 'my-header-style'},
    {text: "Durum", value: "messages", class: 'my-header-style'},
    {text: "Karta Git", value: "actions",align:'end', class: 'header-actions-style'},
  ];


  icraDairesi: boolean = false;

  secilenler = [];
  e1 = 1;

  basariliMi: boolean[] = []
  indirilecekXmller: any = []
  ready = false;


  hataMesajlari: any = [];
  xmlUyari: boolean = false;
  kontrol: boolean = false;
  eksikVarMi: boolean = false;
  takipNasilAcilacak: string = "online";


  @Watch('secimler')
  stepleriTemizle() {
    this.xmlObject = [];
  }
  onlyAllowNumbers(event:any) {
    if ((event.key < '0' || event.key > '9') && event.key !== 'Backspace' && event.key !== 'Enter') {
      event.preventDefault();
    }
  }
  async belirlenenAraliktakiTakipleriKontrolEt() {
    this.stepleriTemizle();
    this.indirilecekXmller = [];
    this.secilenler = [];
    this.kontrol = true;
    this.indirmeyeDevamEt = false;
    this.baslangic = Number(this.baslangic);
    this.bitis = Number(this.bitis);
    for (let i = this.baslangic; i <= this.bitis; i++) {
      try {
        StatusCodeHelper.dontShow404Alert = true;
        let response: any = await this.$http.get('/api/v1/takip/' + i + '/xml-kontrol');
        if (response.takip) {
          //eksik varsa

          let message = "";
          response.hata_mesaji.forEach((hata: string) => {
            message = message + " " + Object.values(hata);
          })

          this.xmlObject.push({status: false, messages: message, kart_no: i, color: 'rgb(255 103 103)'})

        } else {
          //eksik yoksa
          this.xmlObject.push({
            status: true,
            messages: "Takibiniz İndirilmek Üzere Hazır.",
            kart_no: i,
            color: ' rgb(189 255 115)'
          })
          this.indirilecekXmller.push(Number(i))
        }
      } catch (e) {
        this.xmlObject.push({
          status: true,
          messages: "Takip Bulununamadı Lütfen Kontrol Ediniz!",
          kart_no: i,
          color: 'rgb(183,183,183)'
        })
      } finally {
        StatusCodeHelper.dontShow404Alert = false;
      }
    }
    this.kontrol = false;
    this.ready = true;
    if (!this.eksikVarMi) {
      if (this.indirilecekXmller.length != this.xmlObject.length) {
        this.indirmeyeDevamEt = true;
      } else {
        this.indirmeyeDevamEt = false;
        this.xmlIndir(this.indirilecekXmller);
      }
    }
  }

   kartaGit(kartNo:any){
     this.$router.push(ProgramPaths.icrapro + "/takip-ekle/" + kartNo);
   }


  async SecilenTakipleriTakipleriKontrolEt() {
    this.stepleriTemizle();
    this.kontrol = true;
    this.basariliMi = [];
    this.baslangic = null;
    this.indirmeyeDevamEt = false;
    this.bitis = null;
    this.indirilecekXmller = [];
    for (const secilen of this.secilenler) {
      try {
        StatusCodeHelper.dontShow404Alert = true;
        let response: any = await this.$http.get('/api/v1/takip/' + secilen + '/xml-kontrol');
        if (response.takip) {
          //eksik varsa

          let message = "";
          response.hata_mesaji.forEach((hata: string) => {
            message = message + " " + Object.values(hata);
          })

          this.xmlObject.push({status: false, messages: message, kart_no: secilen, color: 'rgb(255 103 103)'})

        } else {
          //eksik yoksa
          this.xmlObject.push({
            status: true,
            messages: "Takibiniz İndirilmek Üzere Hazır.",
            kart_no: secilen,
            color: ' rgb(189 255 115)'
          })
          this.indirilecekXmller.push(Number(secilen))
        }
      } catch (e) {
        this.xmlObject.push({
          status: true,
          messages: "Takip Bulununamadı Lütfen Kontrol Ediniz!",
          kart_no: secilen,
          color: 'rgb(183,183,183)'
        })
      } finally {
        StatusCodeHelper.dontShow404Alert = false;
      }
    }
    this.kontrol = false;
    this.ready = true;
    if (!this.eksikVarMi) {
      if (this.indirilecekXmller.length != this.xmlObject.length) {
        this.indirmeyeDevamEt = true;
      } else {
        this.indirmeyeDevamEt = false;
        this.xmlIndir(this.indirilecekXmller);
      }
    }
  }


  @Watch('icraDairesi')
  onlineManuelCheck() {
    if (this.icraDairesi) {
      this.takipNasilAcilacak = "manuel";
    } else {
      this.takipNasilAcilacak = "online";
    }
  }

  async xmlIndir(degerler: []) {
    console.log("hazır takipleri indir-->", degerler);
    this.xmlUyari = true;
    await this.$http.post('/api/v1/takip/' + this.takipNasilAcilacak + '/toplu-xml-indir', degerler)
        .then((response: any) => {
          var pom = document.createElement('a');
          var data = new Blob([response], {type: 'text/plain'});

          pom.setAttribute('href', window.URL.createObjectURL(data));
          pom.setAttribute('download', 'İcraProTakipXml.xml');
          pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');

          pom.click();
        })
    this.xmlUyari = false;
  }

}
