
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import MahkemeSecim from "@/components/inputs/MahkemeSecim.vue";
import SehirPicker from "@/components/pickers/SehirPicker.vue";
import NoterPicker from "@/components/pickers/NoterPicker.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import Dates from "@/components/inputs/Dates.vue";
import {IlamTipi} from "@/enum/IlamTipi";
import DosyaEsasNumarasi from "@/components/inputs/DosyaEsasNumarasi.vue";
import KararNumarasi from "@/components/inputs/KararNumarasi.vue";
import IlamTipiPicker from "@/components/pickers/IlamTipiPicker.vue";
import {IcraTakipIlamDosyasiUygunMuTalep} from "@/plugins/uyap-plugin/uyap/IcraTakipIlamDosyasiUygunMu";
import {TakipTipi} from "@/enum/TakipTipi";
import DosyaTuruPicker from "@/components/pickers/DosyaTuruPicker.vue";
import YevmiyeNumarasi from "@/components/inputs/YevmiyeNumarasi.vue";
import {defaults} from "xml2js";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import IhtiyatiHacizAlacakKalemiForm from "@/components/forms/alacak-kalemi/IhtiyatiHacizAlacakKalemiForm.vue";
import MahkemeForm from "@/components/forms/MahkemeForm.vue";

@Component({
  computed: {
    defaults() {
      return defaults
    }
  },
  components: {
    MahkemeForm,
    IhtiyatiHacizAlacakKalemiForm, FormDialog,
    YevmiyeNumarasi,
    DosyaTuruPicker,
    IlamTipiPicker,
    KararNumarasi,
    DosyaEsasNumarasi,
    Dates,
    FormWrapper,
    MahkemeSecim,
    SehirPicker,
    NoterPicker,
  },
})
export default class IlamBilgileriForm extends Mixins(ObjectInputMixin) {
  @Prop({default: false}) yevmiyeNoGoster!: boolean;
  @Prop({type: Object}) defaultValues!: any;
  @Prop() takipTipiId!: number;


  ilamTipi = IlamTipi;
  takipTipi = TakipTipi;
  mahkemeData: any = {};
  loading = false;
  sorguButtonColor = 'blue-grey'

  mounted(){
    if (!this.localValue.ilami_veren_kurum && this.localValue.ozel_mahkeme_isim){
      this.localValue.ilami_veren_kurum = this.localValue.ozel_mahkeme_isim
    }
  }

  get ilamId() {
    if (this.mahkemeData.yargi_birimi)
      return this.mahkemeData.yargi_birimi.ilam_turu_id;
    else
      return null;
  }

  get ilamOzetiRule() {
    if (this.localValue.ilam_ozeti) {
      if (this.localValue.ilam_ozeti.match(/["'<>&]/g) == null)
        return true;
      else
        return `Yasak karakter. ", ', <, >, & karakterleri kullanılamaz.`;
    } else
      return 'Lütfen bu alanı doldurun.';
  }

  async uyapSorgu() {
    /*
    this.loading = true;
    let talep: IcraTakipIlamDosyasiUygunMuTalep = {};
    // talep.birimId = this.mahkemeData.yargiBirimiData.uyap_kod; değişecek
    // talep.ilamTuru=
    // talep.dosyaTurKod = this.mahkemeData.dosyaData.kod; değişecek
    // talep.dosyaYil=
    // talep.dosyaSiraNo=
    // talep.TarafList=
    let cevap = await this.$uyap.IcraTakipIlamDosyasiUygunMu().run(talep);
    this.loading = false;
    if (cevap)
      this.sorguButtonColor = 'success';
    else
      this.sorguButtonColor = 'error';
    return cevap;
     */
  }
}
